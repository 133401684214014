import React from "react";

const About = () => {
  return (
    <>
      <div className="row align-items-center justify-content-center">
        <div
          className="col-lg-6 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
        >
          <div className="img-box dark-img-box">
            <img src="img/photo/bwphoto1.jpg" alt="There should be image. Life can be hard." />
          </div>
        </div>

        <div
          className="col-lg-6 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-dealay="100"
        >
          <div className="typo-box about-me">
            <h3>Štefan Bittmann</h3>
            <h5>
              <span className="color-theme">Project manager</span> and <span className="color-theme">Software developer</span> based in{" "}
              <span className="color-theme">Czech Republic</span>
            </h5>
            <p>
             
            </p>
            <div className="row about-list">
              <div className="col-md-6">
                <div className="media">
                  <label>Age</label>
                  <p>34 years</p>
                </div>
                <div className="media">
                  <label>Residence</label>
                  <p>Prague</p>
                </div>
                <div className="media">
                  <label>Education</label>
                  <p>Engineer's degree</p>
                </div>
                <div className="media">
                  <label>Developer experience</label>
                  <p>6+ years</p>
                </div>
                <div className="media">
                  <label>Manager experience</label>
                  <p>8+ years</p>
                </div>
              </div>
              {/* End .col */}

              <div className="col-md-6">
                <div className="media">
                  <label>E-mail</label>
                  <p>stefan@bittmann.cz</p>
                </div>
              
                <div className="media">
                  <label>LinkedIn</label>
                  <p><a href="https://www.linkedin.com/in/%C5%A1tefan-bittmann-80675b64/">Štefan Bittmann</a></p>
                </div>
                <div className="media">
                  <label>Freelance</label>
                  <p>Available from January 2024</p>
                </div>
              </div>
              {/* End .col */}
            </div>
            {/* End .row */}
            {/* <div className="btn-bar">
              <a className="px-btn px-btn-theme" href="img/resume.png" download>
                Download CV12
              </a>
            </div> */}
          </div>
          {/* End .about me */}
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default About;
