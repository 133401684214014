import React from "react";

const ServiceContent = [
  {
    icon: "ti-bar-chart",
    title: "Project management",
    // descriptions: `As a project manager I'm helping my clients with wide range of projects in business domains ranging from SW development, technical production in film, theatre and music festival industries.`,
    descriptions: `I am helping my clients with a wide range of projects in various business domains, including software development, technical production in the movie, theatre, and festival industries.`,
    animationDealy: "",
  },
  {
    icon: "ti-user",
    title: "Team leading",
    // descriptions: `I'm an experienced team leader with a talent for inspiring and guiding others towards success. I thrive in fast-paced environments and enjoy building high-performing teams that exceed expectations.`,
    descriptions: `I motivate and lead teams in their collective work efforts. My focus is on team well-being, communication, and project engagement to create self-organized creative teams.`,
    animationDealy: "100",
  },
  {
    icon: "ti-server",
    title: "Software development",
    descriptions: `At the moment, software development is a secondary interest for me. However, I am actively working on multiple private SaaS projects in the education sector and exploring new technological trends.`,
    animationDealy: "200",
  },
  // {
  //   icon: "ti-brush-alt",
  //   title: "Consulting",
  //   descriptions: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
  //   eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  //   animationDealy: "300",
  // },
  // {
  //   icon: "ti-camera",
  //   title: "Photography",
  //   descriptions: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
  //   eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  //   animationDealy: "400",
  // },
  // {
  //   icon: "ti-world",
  //   title: "Mobile apps",
  //   descriptions: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
  //   eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  //   animationDealy: "500",
  // },
];

export default function Service() {
  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div
            className="col-md-6 col-lg-4 my-3"
            key={i}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-dealay={val.animationDealy}
          >
            <div className="feature-box-01">
              <div>
                <div className="icon">
                  <i className={val.icon}></i>
                </div>
                <div className="feature-content">
                  <h5>{val.title}</h5>
                  <p>{val.descriptions}</p>
                </div>
              </div>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
        {/* End .col */}
      </div>
    </>
  );
}
