import React from "react";

const Introduction = () => {

   return (
      <div className="container">
         <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
               <h3 className="font-alt">Introduction</h3>
               <p>
                  My story started as a full stack .NET software developer working in various large scale international projects for corporations such as Radio Free Europe, Unicorn systems and such. 
                  <br/>
                  After six years of software development, I've realized that I'm more people and problem-solving focused rather than a lonely software wolf. This led me to a whole new path: diving into project management, especially focusing on innovation management.
               </p>
            </div>
         </div>
         {/* End .row */}
      </div>
   );
};

export default Introduction;