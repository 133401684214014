import React from "react";

const Skills = () => {
  return (
    <>
      <div className="row">
        <div
          className="col-lg-6 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
        >
          <div className="sm-title">
            <h4 className="font-alt">Education</h4>
          </div>

          <ul className="aducation-box theme-bg">
            <li>
              <span className="dark-bg">2020-2022</span>
              <h6>Project Management of Inovations</h6>
              <p>University of Chemistry and Technology, Prague</p>
              <p>Master's degree</p>
            </li>

            <li>
              <span className="dark-bg">2011-2015</span>
              <h6>Systems Engineering and Informatics</h6>
              <p>Unicorn College, Prague</p>
              <p>Bachelor's degree</p>
            </li>

            <li>
              <span className="dark-bg">2005-2009</span>
              <h6>Electronic Computer Systems</h6>
              <p>SPŠE Ječná, Prague</p>
              <p>High school diploma</p>
            </li>
          </ul>

          {/* End  .aducation-box */}
        </div>
        {/* End .col */}

        <div
          className="col-lg-6 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="sm-title">
            <h4 className="font-alt">Experience</h4>
          </div>
          <ul className="aducation-box dark-bg">
            <li>
              <span className="theme-bg">2022-2023</span>
              <h6>Team leader</h6>
              <h7>Leading a team of set dressers, managing the project lifecycle, and coordinating multiple departments on an international film project.</h7>
              <p>Milk and Honey pictures</p>
            </li>

            <li>
              <span className="theme-bg">2022</span>
              <h6>Business developer</h6>
              <h7>Technical production of AV projects. Business development, key account management.</h7>
              <p>3Dsense</p>
            </li>

            <li>
              <span className="theme-bg">2017-2022</span>
              <h6>Senior technical production manager</h6>
              <h7>Managing team of light designers, sound designers and technicians. Technical production of international theatre projects. Financial and executive responsibility for technical operations</h7>
              <p>Jatka78</p>
            </li>

            <li>
              <span className="theme-bg">2013-2017</span>
              <h6>.NET Software developer</h6>
              <h7></h7>
              <p>Radio Free Europe</p>
              <p>Unicorn systems</p>
              <p>Taures</p>
            </li>

          </ul>
          {/* End  .aducation-box */}
        </div>
        {/* End .col */}

        {/* <div
          className="col-lg-4 ml-auto my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <div className="sm-title">
            <h4 className="font-alt">My Skills</h4>
          </div>
          <div className="gray-bg skill-box">
            <div className="skill-lt">
              <h6>React js</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 92 + "%" }}
                >
                  <span>92%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt //

            <div className="skill-lt">
              <h6>.NET</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 75 + "%" }}
                >
                  <span>75%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt //

            <div className="skill-lt">
              <h6>Vue js</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 86 + "%" }}
                >
                  <span>86%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt //

            <div className="skill-lt">
              <h6>UI/UX</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 88 + "%" }}
                >
                  <span>88%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt //

            <div className="skill-lt">
              <h6>CSS3</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 92 + "%" }}
                >
                  <span>92%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt //
          </div>
        </div> */}
        {/* End .col */}
      </div>
    </>
  );
};

export default Skills;
